/** @jsx jsx */
import { jsx, Box } from 'theme-ui';
import VisionIcon from '../../assets/svgs/vision.svg';
import MissionIcon from '../../assets/svgs/mission.svg';
import { Document } from '@contentful/rich-text-types';
import Fact from './Fact';

type Fact = {
  icon: string;
  text: Document;
};

type CompanyFacts = {
  vision: Fact;
  mission: Fact;
};

const IconNameMap = {
  vision: VisionIcon,
  mission: MissionIcon,
};

const CompanyFacts: React.FC<CompanyFacts> = ({ vision, mission }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexWrap: 'wrap',
      }}
    >
      <Fact text={vision.text} Icon={IconNameMap[vision.icon]}></Fact>
      <Fact text={mission.text} Icon={IconNameMap[mission.icon]}></Fact>
    </Box>
  );
};

export default CompanyFacts;
