/** @jsx jsx */
import { jsx, Box, ThemeProvider } from 'theme-ui';
import RichText from '../richText/RichText';
import { Document } from '@contentful/rich-text-types';

type FactProps = {
  text: Document;
  Icon: React.FC<React.SVGAttributes<SVGSVGElement>>;
};

const Fact: React.FC<FactProps> = ({ text, Icon }) => {
  return (
    <Box
      sx={{
        borderRadius: '9px',
        width: ['335px', '395px', '395px'],
        height: ['198px', '265px', '265px'],
        padding: ['36px 29px 0px 35px', '36px 35px 0px 35px', '36px 35px 0px 35px'],
        margin: ['0', '0 25px', '0 25px'],
        marginBottom: ['40px', '40px', '40px'],
        boxShadow: '0px 2px 10px 0px rgba(0, 0, 0, 0.2)',
        display: 'flex',
        flexDirection: 'Column',
        alignItems: 'flex-start',
        textAlign: 'start',
      }}
    >
      <Icon
        sx={{
          height: '40px',
          width: '40px',
          marginBottom: ['7px', '20px', ' 20px'],
        }}
      />
      <ThemeProvider
        theme={{
          styles: {
            h3: {
              fontSize: ['22px', '30px', '30px'],
              lineHeight: ['41px', '41px', '41px'],
              marginBottom: ['9px', '20px', '20px'],
            },
            p: {
              fontSize: ['14px', '20px', '20px'],
              lineHeight: ['18px', '25px', '25px'],
              marginBottom: ['0', '0', '0'],
            },
          },
        }}
      >
        <RichText richTextObject={text} />
      </ThemeProvider>
    </Box>
  );
};

Fact.displayName = 'Fact';
export default Fact;
