/** @jsx jsx */
import { jsx, Box, Flex, Themed } from 'theme-ui';
import { Container } from '@sprinklr/shared-lib';
import { OfficeInfo } from '@sprinklr/shared-lib';
import CompanyFacts from '@sprinklr/shared-lib/components/companyFacts';
import { SPRINKLR_FACTS } from './constants';
import EmployeesFact from '@sprinklr/shared-lib/assets/svgs/fact-employees.svg';
import LanguagesFact from '@sprinklr/shared-lib/assets/svgs/fact-languages.svg';
import OfficesFact from '@sprinklr/shared-lib/assets/svgs/fact-offices.svg';
import TimezonesFact from '@sprinklr/shared-lib/assets/svgs/fact-timezones.svg';
import NewYorkOffice from '@sprinklr/shared-lib/assets/svgs/newyork.svg';
import LondonOffice from '@sprinklr/shared-lib/assets/svgs/london.svg';
import SingaporeOffice from '@sprinklr/shared-lib/assets/svgs/singapore.svg';
import { useContainerPadding } from '@sprinklr/shared-lib/hooks';
import { ContainerPadding } from '@sprinklr/shared-lib/@types/types';

type SprinklrFactsProps = {
  headline: string;
  facts: string[];
  offices?: {
    icon: string;
    text: string;
    location: string;
  }[];
  padding?: ContainerPadding;
  companyData?: any;
};

const IconNameMap = {
  'fact-employees': EmployeesFact,
  'fact-languages': LanguagesFact,
  'fact-offices': OfficesFact,
  'fact-timezones': TimezonesFact,
  newyork: NewYorkOffice,
  london: LondonOffice,
  singapore: SingaporeOffice,
};

const SprinklrFacts: React.FC<SprinklrFactsProps> = ({
  headline,
  facts = [],
  padding,
  companyData,
}) => {
  const [pt, pb] = useContainerPadding(padding);

  return (
    <Container containerSx={{ pt, pb }}>
      <Flex sx={{ flexDirection: 'column', alignItems: 'center' }}>
        <Themed.h2
          sx={{
            textAlign: 'center',
            marginBottom: ['40px', '89px', '89px'],
          }}
        >
          {headline}
        </Themed.h2>

        <Box sx={{ marginBottom: ['23px', '92px', '92px'] }}>
          <CompanyFacts
            vision={{
              icon: 'vision',
              text: companyData?.edges[0]?.node?.vision,
            }}
            mission={{
              icon: 'mission',
              text: companyData?.edges[0]?.node?.mission,
            }}
          />
        </Box>

        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexWrap: 'wrap',
          }}
        >
          {facts.map(iconName => {
            const Icon = IconNameMap[iconName];
            return (
              <Icon
                sx={{
                  fill: 'white',
                  color: 'white',
                  width: '250px',
                  margin: ['0px 28px 40px', '0px 28px 72px', '0px 28px 72px'],
                }}
                key={iconName}
              />
            );
          })}
        </Box>

        <Box
          sx={{
            display: 'flex',
            alignItems: 'baseline',
            justifyContent: 'center',
            flexWrap: 'wrap',
          }}
        >
          <OfficeInfo
            text={companyData?.edges[0]?.node?.emeaHq}
            Icon={LondonOffice}
          />
          <OfficeInfo
            text={companyData?.edges[0]?.node?.hq}
            Icon={NewYorkOffice}
          />
          <OfficeInfo
            text={companyData?.edges[0]?.node?.apacHq}
            Icon={SingaporeOffice}
          />
        </Box>
      </Flex>
    </Container>
  );
};

export const SprinklrFactsValueWrapper = ({ padding, companyData }) => (
  <SprinklrFacts
    padding={padding}
    headline={SPRINKLR_FACTS?.headline}
    facts={SPRINKLR_FACTS?.facts}
    offices={SPRINKLR_FACTS?.offices}
    companyData={companyData}
  />
);

export default SprinklrFacts;
