export const SPRINKLR_FACTS = {
  headline: 'Sprinklr Facts',
  facts: ['fact-offices', 'fact-timezones', 'fact-languages'],
  offices: [
    {
      icon: 'london',
      text: 'EMEA HQ:',
      location: 'London',
    },
    {
      icon: 'newyork',
      text: 'HQ in',
      location: 'NYC',
    },
    {
      icon: 'singapore',
      text: 'APAC HQ:',
      location: 'Singapore',
    },
  ],
};
